@import "../../sass/style.scss";

.footer {
  background-color: $black;
  padding: 5px 0 5px 0;

  @media #{$mobile} {
    padding: 20px 0 33px 0;
  }
}

.ul_list {
  @include flex($justify_content: space-between);
  width: 40%;
  margin: auto;
  list-style: none;
  margin: 20px auto;

  @media #{$tabDesk} {
    width: 90%;
  }

  @media #{$mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.ul_listSecond {
  @include flex($justify_content: space-between);
  width: 60%;
  margin: auto;
  list-style: none;

  @media #{$tabDesk} {
    width: 90%;
  }

  @media #{$mobile} {
    flex-direction: column;
  }
}

.ul_listSecond1 {
  @include flex($justify_content: space-between);
  width: 55%;
  margin: auto;
  list-style: none;

  @media #{$tabDesk} {
    width: 70%;
  }

  @media #{$mobile} {
    display: none;
  }
}

.desktop {
  display: block;

  @media #{$mobile} {
    display: none;
  }
}

.dropdownContent {
  opacity: 0;
  overflow: hidden;
  transition: 0.6s ease, opacity 0.6s ease;
  position: relative;

  @media #{$mobile} {
    max-height: 0;
  }

  &.visible {
    opacity: 1;

    @media #{$mobile} {
      max-height: 170px;
    }
  }
}

.test {
  display: block;
}

.iconClose {
  color: $black;

  @media #{$mobile} {
    transition: transform 0.3s ease-in-out;
    color: $white;
    margin-left: 5px;
    cursor: pointer;

    &.up {
      transform: rotate(180deg);
    }
  }
}

.navigateTabs {
  padding: 0;

  @media #{$mobile} {
    margin-bottom: 45px;
  }
}

.navigateTabsSecond {
  padding: 0;
  display: none;

  @media #{$mobile} {
    display: block;
    margin-bottom: 20px;
  }
}

.navigateTabsSecond1 {
  @media #{$mobile} {
    padding: 0;
    display: none;
  }
}

.ul_listBottom {
  list-style: none;

  @media #{$mobile} {
    padding: 0;
  }
}

.list_wordFirst {
  text-decoration: none;
  font-size: 18px;
  font-family: $rubik;
  font-weight: 600;
  color: $white;

  &:hover,
  &:active {
    color: $mainColor;
  }
}

.lineSeperate {
  width: 80%;
  height: 1px;
  margin: auto;
  background-color: $white;
  margin: 30px auto 0 auto;

  @media #{$mobile} {
    margin: 0 auto 5px auto;

    width: 90%;
  }

  @media #{$tabDesk} {
    width: 90%;
  }
}

.logoMediaContainer {
  @include flex($justify_content: space-between);
  width: 60%;
  margin: auto;

  @media #{$tabDesk} {
    width: 90%;
  }

  @media #{$mobile} {
    flex-direction: column;
    width: 100%;
  }
}

.logo {
  height: 50px;

  @media #{$mobile} {
    height: 54px;
  }
}

.rightPrivacyStyle {
  @include flex();
  gap: 120px;

  @media #{$tabDesk} {
    gap: 50px;

  }

  @media #{$mobile} {
    width: 100%;
    justify-content: space-evenly;
    gap: 0;
    margin: 20px 0 20px 0;
  }
}

.list_wordSecondLink {
  text-decoration: none;
  font-size: 14px;
  color: $white;
  font-family: $rubik;
  font-weight: 300;

  &:hover,
  &:active {
    color: $mainColor;
  }
}

.list_wordSecondLink1 {
  text-decoration: none;
  font-size: 18px;
  color: $white;
  font-family: $rubik;
  font-weight: 600;

  &:hover,
  &:active {
    color: $mainColor;
  }
}

.rightsSentence {
  font-size: 14px;
  font-family: $inter;
  font-weight: 600;
  color: $white;

  @media #{$mobile} {
    color: $white;
  }
}

.policySentence {
  font-size: 14px;
  font-family: $inter;
  font-weight: 600;
  color: $white;
  cursor: pointer;

  &:hover,
  &:active {
    color: $mainColor;
  }

  @media #{$mobile} {
    color: $white;
    display: none;
  }
}


.policySentence1 {
  display: none;

  &:hover,
  &:active {
    color: $mainColor;
  }

  @media #{$mobile} {
    color: $white;
    display: block;
    font-size: 14px;
    font-family: $inter;
    font-weight: 600;
    color: $white;
    cursor: pointer;
  }
}

.footerStyle {
  @media #{$mobile} {
    padding: 10px 20px 0 80px;
  }
}


.mediaIcon {
  margin: 0 14px 0 0;

  &:hover,
  &:active {
    color: $mainColor;
  }

  @media #{$mobile} {

    margin: auto 55px auto auto;

    &:hover {
      color: $mainColor;
    }
  }
}

.verticalsMeth {
  text-decoration: underline !important;

  @media #{$mobile} {
    text-decoration: none !important;
  }
}

.gamingStyleLogo {
  width: 90px;
  cursor: pointer;
}